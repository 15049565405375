<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        <!--{{user.name}}-->
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.prohibited_words.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="Word" v-model="word.word" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Created" v-model="word.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-checkbox label="Active" v-model="word.is_active" disabled></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import ProhibitedWords from "../../../models/ProhibitedWords";

    export default {
        name: "prohibited-words-show",
        components: {
            AdminDashboardLayout,
        },
        data: function () {
            return {
                word: {},
            }
        },
        async mounted() {
            await ProhibitedWords.find(this.$route.params.id).then((res) => {
                this.word = res.data
            })
        },
    }
</script>

<style lang="scss">
</style>
